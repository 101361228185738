import { Injectable } from '@angular/core';
import { ReplaySubject, Subscription } from 'rxjs';
import {
  ONBOARDING_COACH_SELECTED,
  ONBOARDING_LAST_SLIDE_VISITED,
  ONBOARDING_ORIGIN,
  ONBOARDING_SKIPPED_SLIDE,
  Slides,
} from '../pages/onboarding/onboarding';
import { Coach } from '@mightyhealth/library';
import { CHRONIC_CONDITIONS_KEY_PATH_MAP } from '../pages/onboarding-v2/chronic-conditions.const';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  readonly onboardingAnswersKey = 'ONBOARDING_ANSWERS_KEY';
  readonly healthGoalKey = 'health_goal';
  readonly reasonKey = 'reason';
  readonly chronicConditionKey = 'chronic_condition';
  readonly extraMessageKey = 'extra_message';
  readonly hearAboutUs = 'hear_about_us';
  readonly healthInsurance = 'health_insurance';
  readonly chronicConditionAnswersKeys = {
    high_blood_pressure_or_heart_diseases: {
      key: 'high_blood_pressure_or_hearth_diseases',
      label: 'High blood pressure or hearth diseases',
      answers: { systolicBloodPressure: 'systolic_blood_pressure' },
    },
    high_cholesterol: {
      key: 'high_cholesterol',
      label: 'High cholesterol',
      answers: {
        lowDensityLipoprotein: 'low_density_lipoprotein',
      },
    },
    joint_pain: {
      key: 'joint_pain',
      label: 'Joint pain',
      answers: {
        jointPainSymptomatic: 'joint_pain_symptomatic',
        painWhenResting: 'pain_when_resting',
        painWhenActivity: 'pain_when_activity',
      },
    },
    'diabetes_or_pre-diabetes': {
      key: 'diabetes_or_pre-diabetes',
      label: 'Diabetes or pre-diabetes',
      answers: { a1c: 'a1c' },
    },
    anxiety_or_depression: {
      key: 'anxiety_or_depression',
      label: 'Anxiety or depression',
      answers: {
        feelingNervousAnxiousOronEdge: 'feeling_nervous_anxious_or_on_edge',
        notBeingAbleToStopOrControlWorrying: 'not_being_able_to_stop_or_control_worrying',
        worryingTooMuchAboutDifferentThings: 'worrying_too_much_about_different_things',
        troubleRelaxing: 'trouble_relaxing',
        beingSoRestlessThatItsHardToSitStill: 'being_so_restless_that_its_hard_to_sit_still',
        becomingEasilyAnnoyedOrIrritable: 'becoming_easily_annoyed_or_irritable',
        feelingAfraidAsIfSomethingAwfulMightHappen: 'feeling_afraid_as_if_something_awful_might_happen',
      },
    },
    overweight: {
      key: 'overweight',
      label: 'Overweight',
      answers: {
        currentWeight: 'current_weight',
        goalWeight: 'goal_weight',
      },
    },
    difficulty_with_walking: {
      key: 'difficulty_with_walking',
      label: 'Difficulty with walking',
      answers: {
        doYouNeedAnAssistDeviceToWalk: 'do_you_need_an_assist_device_to_walk',
        deviceToWalk: 'device_to_walk',
        numberOfBlocks: 'number_of_blocks',
      },
    },
    other: { label: 'Other' },
  };
  readonly personalInfos = 'personal_infos';
  readonly partnerKey = 'partner_key';
  readonly softCheck = 'soft_check';

  private currentRoute: ReplaySubject<string | undefined> = new ReplaySubject(1);

  private currentAnwsers: ReplaySubject<string | undefined> = new ReplaySubject(1);

  private storage: Storage | undefined;

  constructor() {
    try {
      this.storage = (globalThis as any).window?.localStorage;
    } catch (e) {
      console.log('Failed to access localStorage');
    }
  }

  getOnboardingAllAnswers() {
    const onboardingAnswers = this.storage?.getItem(this.onboardingAnswersKey);
    if (onboardingAnswers) {
      return JSON.parse(onboardingAnswers);
    } else {
      return undefined;
    }
  }

  getOnboardingAnswerByKey(key: string) {
    const onboardingAnswers = this.storage?.getItem(this.onboardingAnswersKey);
    if (onboardingAnswers) {
      return JSON.parse(onboardingAnswers)[key];
    } else {
      return undefined;
    }
  }

  setOnboardingFromMemberAnswers(answers: object) {
    this.storage?.setItem(this.onboardingAnswersKey, JSON.stringify(answers));
  }

  setOnboarding(key: string, values: any) {
    const oldValues = this.getOnboardingAllAnswers();
    const newValues = { [key]: values };
    this.storage?.setItem(this.onboardingAnswersKey, JSON.stringify({ ...oldValues, ...newValues }));
    this.currentAnwsers.next({ ...oldValues, ...newValues });
  }

  setQ4Answer(disease: string, key: string, values: any) {
    const oldValues = this.getOnboardingAllAnswers();
    const newValues = { [key]: values };
    this.storage?.setItem(
      this.onboardingAnswersKey,
      JSON.stringify({
        ...oldValues,
        [disease]: {
          ...oldValues[disease],
          ...newValues,
        },
      })
    );
    this.currentAnwsers.next({ ...oldValues, ...newValues });
  }

  getQ4Answer(disease: string, key: string) {
    const answers = this.getOnboardingAllAnswers();
    return answers[disease]?.[key] ?? null;
  }

  resetOnboarding() {
    this.storage?.removeItem(this.onboardingAnswersKey);
  }

  setSkipFromSlide() {
    this.storage?.setItem(ONBOARDING_SKIPPED_SLIDE, Slides.WELCOME);
  }

  getSkipFromSlide(): string | null | undefined {
    return this.storage?.getItem(ONBOARDING_SKIPPED_SLIDE);
  }

  removeSkipFromSlide() {
    this.storage?.removeItem(ONBOARDING_SKIPPED_SLIDE);
  }

  setLastSlideVisited(slide: string) {
    this.storage?.setItem(ONBOARDING_LAST_SLIDE_VISITED, slide);
  }

  getLastSlideVisited(): string | null | undefined {
    return this.storage?.getItem(ONBOARDING_LAST_SLIDE_VISITED);
  }

  removeLastSlideVisited() {
    this.storage?.removeItem(ONBOARDING_LAST_SLIDE_VISITED);
  }

  setCoach(coach: Coach) {
    this.storage?.setItem(ONBOARDING_COACH_SELECTED, JSON.stringify(coach));
  }

  getCoach(): Coach | null {
    const coachStorage = this.storage?.getItem(ONBOARDING_COACH_SELECTED);
    if (coachStorage) {
      return JSON.parse(coachStorage);
    } else {
      return null;
    }
  }

  resetAllOnboarding() {
    this.storage?.removeItem(ONBOARDING_ORIGIN);
    this.storage?.removeItem(ONBOARDING_SKIPPED_SLIDE);
    this.storage?.removeItem(ONBOARDING_LAST_SLIDE_VISITED);
    this.storage?.removeItem(ONBOARDING_COACH_SELECTED);
  }

  async getCurrentAwnsers(next: (value: string | undefined) => void): Promise<Subscription> {
    return this.currentAnwsers.subscribe(next);
  }

  async getCurrentRoute(next: (value: string | undefined) => void): Promise<Subscription> {
    return this.currentRoute.subscribe(next);
  }

  async setCurrentRoute(member: string | undefined) {
    this.currentRoute.next(member);
  }

  updateChronicConditionsAnswers() {
    const answers = this.getOnboardingAllAnswers();
    // console.log('previous', JSON.stringify(answers, null, 2));
    const chronicConditionsSelected = answers?.['chronic_condition'];

    const newAnswers = Object.keys(answers).reduce((acc: any, key: string) => {
      const isChronicConditionKey = CHRONIC_CONDITIONS_KEY_PATH_MAP[key];
      if (isChronicConditionKey) {
        if (chronicConditionsSelected.includes(key)) {
          acc[key] = answers[key];
        }
        return acc;
      }
      acc[key] = answers[key];

      return acc;
    }, {});

    // console.log('new', JSON.stringify(newAnswers, null, 2));
    this.setOnboardingFromMemberAnswers(newAnswers);
  }
}
