import { chronicConditions } from './chronic-conditions.const';

export const ONBOARDING_STEPS_DEFINITION_QUESTIONNAIRE_FIRST = [
  'intro',
  'health-goal',
  'health-testimonial',
  'health-goal-reason',
  'program-designed-for-you',
  'your-health-is-interconnected',
  chronicConditions.CHRONIC_CONDITIONS_KEY,
  'program-support',
  'creating-program',
  'date-of-birth', // if not-logged
  'soft-eligibility',
  'signup', // if not-logged
  'health-insurance',
];

export const ONBOARDING_STEPS_DEFINITION_NO_HARD_ELIGIBILITY_STEPS_PAGE = [
  'first-steps',
  'health-goal',
  'health-testimonial',
  'health-goal-reason',
  'program-designed-for-you',
  'your-health-is-interconnected',
  chronicConditions.CHRONIC_CONDITIONS_KEY,
  'program-support',
  'creating-program',
  'date-of-birth', // if not-logged
  'soft-eligibility',
  'signup', // if not-logged
];

export const ONBOARDING_STEPS_DEFINITION_DIRECT = [
  'intro',
  'date-of-birth', // if not-logged
  'soft-eligibility',
  'signup', // if not-logged
  'health-insurance',
];
