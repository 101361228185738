import { inject, Injectable } from '@angular/core';
import {
  ONBOARDING_STEPS_DEFINITION_DIRECT,
  ONBOARDING_STEPS_DEFINITION_NO_HARD_ELIGIBILITY_STEPS_PAGE,
  ONBOARDING_STEPS_DEFINITION_QUESTIONNAIRE_FIRST,
} from '../pages/onboarding-v2/onboarding-steps-definition';
import { ExperimentService } from '../utils/experiment.service';
import { StorageService } from '../utils/storage.service';

interface OnboardingVariant {
  name: string;
  route: string[];
}

const ONBOARDING_EXPERIMENT_KEY = 'web-onboarding';

// This is being used by scan, marshfield and optum onboardings
export const WEB_ONBOARDING_QUESTIONNAIRE_FIRST_VARIANT: OnboardingVariant = {
  name: 'web_onboarding_questionnaire_first_variant',
  route: ONBOARDING_STEPS_DEFINITION_QUESTIONNAIRE_FIRST,
};

const WEB_ONBOARDING_DIRECT_VARIANT: OnboardingVariant = {
  name: 'web_onboarding_direct',
  route: ONBOARDING_STEPS_DEFINITION_DIRECT,
};

const WEB_ONBOARDING_NO_HARD_ELIGIBILITY_STEPS_PAGE_VARIANT: OnboardingVariant = {
  name: 'web_onboarding_no_eligibility_steps_page',
  route: ONBOARDING_STEPS_DEFINITION_NO_HARD_ELIGIBILITY_STEPS_PAGE,
};

const WEB_ONBOARDING_RISK_FREE_PAGE_VARIANT: OnboardingVariant = {
  name: 'web_onboarding_risk_free_page',
  route: ONBOARDING_STEPS_DEFINITION_NO_HARD_ELIGIBILITY_STEPS_PAGE,
};

@Injectable({
  providedIn: 'root',
})
export class OnboardingExperimentService {
  storage = inject(StorageService);
  experimentService = inject(ExperimentService);

  async startOnboardingExperiment(forceVariant?: string): Promise<OnboardingVariant> {
    try {
      const variant = await this.experimentService.startExperiment(
        ONBOARDING_EXPERIMENT_KEY,
        [WEB_ONBOARDING_RISK_FREE_PAGE_VARIANT.name, WEB_ONBOARDING_NO_HARD_ELIGIBILITY_STEPS_PAGE_VARIANT.name],
        forceVariant
      );

      return this.selectOnboardingVariant(variant);
    } catch (error) {
      console.error('Error starting onboarding experiment:', error);
      return WEB_ONBOARDING_NO_HARD_ELIGIBILITY_STEPS_PAGE_VARIANT;
    }
  }

  private selectOnboardingVariant(variant: string): OnboardingVariant {
    const variantMap: Record<string, OnboardingVariant> = {
      [WEB_ONBOARDING_DIRECT_VARIANT.name]: WEB_ONBOARDING_DIRECT_VARIANT,
      [WEB_ONBOARDING_QUESTIONNAIRE_FIRST_VARIANT.name]: WEB_ONBOARDING_QUESTIONNAIRE_FIRST_VARIANT,
      [WEB_ONBOARDING_NO_HARD_ELIGIBILITY_STEPS_PAGE_VARIANT.name]:
        WEB_ONBOARDING_NO_HARD_ELIGIBILITY_STEPS_PAGE_VARIANT,
    };

    const selectedVariant = variantMap[variant] || WEB_ONBOARDING_NO_HARD_ELIGIBILITY_STEPS_PAGE_VARIANT;
    ONBOARDING_SELECTED_VARIANT = selectedVariant;

    return selectedVariant;
  }

  getExperimentPath() {
    if (this.storage.get(ONBOARDING_EXPERIMENT_KEY) === WEB_ONBOARDING_RISK_FREE_PAGE_VARIANT.name) {
      return '/onboarding/risk-free-call';
    } else {
      return '/pre-scheduling';
    }
  }
}

export let ONBOARDING_SELECTED_VARIANT: OnboardingVariant;
